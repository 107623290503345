

















































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import api from "@/api";
import {
  DataDictionaryDtoPagedResultDto,
  FundIncomeRecordDto,
} from "@/api/appService";

@Component({
  name: "FundIncomeRecordDetail",
  filters: {
    formatType(type: string, typeList: any[]) {
      let result = "";
      typeList.forEach((item) => {
        if (item.value == type) {
          result = item.text;
          return;
        }
      });
      return result;
    },
  },
})
export default class FundIncomeRecordDetail extends Vue {
  id = 0;
  detail: FundIncomeRecordDto = {};
  typeList: any = [];
  styles = "";
  selector = "table-detail-world";
  filename = "捐赠记录详情";
  donorType: any = [];
  incomeType: any = [];
  paymentType: any = [];
  currencyType: any = [];

  loading = true;

  created() {
    this.id = parseInt(this.$route.params.id);
    this.fetchEnumType();
    this.fetchDataDictionary();
    this.fetchData(this.id);
  }

  @Watch("$route")
  onRouteChange() {
    this.id = parseInt(this.$route.params.id);
    this.fetchData(this.id);
  }

  async fetchDataDictionary() {
    await api.dataDictionary
      .getDataDictionaryListByKey({ key: "PaymentType", maxResultCount: 1000 })
      .then((res: DataDictionaryDtoPagedResultDto) => {
        this.paymentType = res.items;
      });
  }

  async fetchEnumType() {
    // 捐赠者类型
    await api.enumService
      .getValues({
        typeName: "DonorType",
      })
      .then((res) => {
        this.donorType = res;
      });
    await api.enumService
      .getValues({
        typeName: "IncomeType",
      })
      .then((res) => {
        this.incomeType = res;
      });
    // 币种
    await api.enumService
      .getValues({
        typeName: "CurrencyType",
      })
      .then((res) => {
        this.currencyType = res;
      });
  }

  fetchData(id: number) {
    this.loading = true;
    api.fundIncomeRecord.get({ id: id }).then((res) => {
      this.detail = { ...res };
      this.loading = false;
    });
  }
}
